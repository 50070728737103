import { Controller } from "@hotwired/stimulus";
import {
  GetContextMenuItemsParams,
  GetRowIdParams,
  GridOptions,
  ToolPanelDef,
  createGrid,
  GridApi,
  KeyCreatorParams,
  ValueFormatterParams,
  IServerSideDatasource,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
  SetFilterValuesFuncParams,
  CellDoubleClickedEvent,
  IRowNode,
  FilterModel,
  ColumnState,
  ProcessCellForExportParams,
  ProcessRowGroupForExportParams,
  IsServerSideGroupOpenByDefaultParams,
} from "ag-grid-enterprise";
import { dataTypeDefinitions } from "../helpers/reports/formatters";
import { post, get } from "@rails/request.js";
import { CsvExporter } from "../helpers/register/csv_exporter";
import { IDetailedLedgerEntry, IServerSideData } from "../types/register";
import { accountCodeRenderer, tagRenderer } from "../helpers/register/renderers";

const createServerSideDatasource = (
  datasourceUrl: string,
  setLoadingState: (isLoading: boolean) => void,
  getCashFlowFilter: () => boolean,
) => {
  return {
    getRows: (params: IServerSideGetRowsParams) => {
      const newRequest: IServerSideGetRowsRequest & { filterForCashFlow: boolean } = {
        ...params.request,
        filterForCashFlow: getCashFlowFilter(),
      };

      // When using a set filter, the grid uses null for "empty" values. Rails removes these values on the server
      // side, so we need to swap out the null with a custom value
      Object.keys(newRequest.filterModel).forEach((key) => {
        const filterModel = newRequest.filterModel[key];

        if (filterModel?.filterType === "set") {
          const values = filterModel.values;

          if (values.includes(null)) {
            values[values.indexOf(null)] = "__empty__";
          }
        }
      });

      // For tags and tag groups, the grid sends an array of group keys, we need to flatten this to a single array.
      // If there is more than one group key in the original array, we should raise an error.
      newRequest.groupKeys.forEach((groupKey) => {
        if (Array.isArray(groupKey)) {
          if (groupKey.length > 1) {
            throw new Error(`Expected a single group key, got multiple: ${groupKey}`);
          }
        }
      });

      newRequest.groupKeys = newRequest.groupKeys.flat();

      setLoadingState(true);

      post(datasourceUrl, {
        body: { detailed_ledger_entry: newRequest },
        responseKind: "json",
      })
        .then((httpResponse) => httpResponse.json)
        .then((response: IServerSideData) => {
          params.success({
            rowData: response.rows,
            rowCount: response.lastRow,
          });
        })
        .finally(() => {
          setLoadingState(false);
        })
        .catch((error) => {
          console.error(error);
          params.fail();
        });
    },
  } as IServerSideDatasource;
};

const getFilterOptions = (url: string, params: SetFilterValuesFuncParams) => {
  const queryParams = new URLSearchParams();
  const filterColumn = params.colDef.field;

  queryParams.append("filter", filterColumn);

  get(`${url}?${queryParams.toString()}`, {
    responseKind: "json",
  })
    .then((response) => response.json)
    .then((data) => {
      params.success(data.filter[filterColumn]);
    })
    .catch((error) => {
      console.error(error);
    });
};

const primaryKeyCreator = (params: KeyCreatorParams) => {
  return params.value.id;
};

const objectValueFormatter = (params: ValueFormatterParams) => {
  return params.value.name;
};

// Connects to data-controller="advanced-register"
export default class extends Controller {
  static targets = [
    "grid",
    "accountCodeTemplate",
    "tagTemplate",
    "showLedgerTransactionButtonWrapper",
    "ledgerEntryCount",
    "clearSelectionButton",
    "bulkUpdateButton",
    "bulkEditErrors",
    "bulkUpdateModal",
    "ledgerEntryIdInputContainer",
    "ledgerEntryIdInputTemplate",
    "cashFlowAlert",
  ];

  static values = {
    datasourceUrl: String,
    filtersUrl: String,
    selectedIds: Array,
    maxSelectableIds: {
      type: Number,
      default: 500,
    },
    isRowSelectionEnabled: {
      type: Boolean,
      default: true,
    },
    isGroupingByRows: {
      type: Boolean,
      default: false,
    },
    filterModel: {
      type: Object,
      default: {},
    },
    columnState: {
      type: Array,
      default: [],
    },
    expandAllGroups: {
      type: Boolean,
      default: false,
    },
    // When we first show the grid, we still haven't loaded the data from the server, so the default here is true until
    // the grid is ready and we have loaded the data.
    gridIsLoading: {
      type: Boolean,
      default: true,
    },
    filterForCashFlow: {
      type: Boolean,
      default: false,
    },
  };

  // Stimulus Values
  declare datasourceUrlValue: string;
  declare filtersUrlValue: string;
  declare selectedIdsValue: string[];
  declare maxSelectableIdsValue: number;
  declare isRowSelectionEnabledValue: boolean;
  declare isGroupingByRowsValue: boolean;
  declare filterModelValue: FilterModel;
  declare columnStateValue: ColumnState[];
  declare expandAllGroupsValue: boolean;
  declare gridIsLoadingValue: boolean;
  declare filterForCashFlowValue: boolean;

  // Stimulus Targets
  declare gridTarget: HTMLDivElement;
  declare accountCodeTemplateTarget: HTMLTemplateElement;
  declare tagTemplateTarget: HTMLTemplateElement;

  declare hasShowLedgerTransactionButtonWrapperTarget: boolean;
  declare showLedgerTransactionButtonWrapperTarget: HTMLDivElement;
  declare hasCashFlowAlertTarget: boolean;
  declare cashFlowAlertTarget: HTMLDivElement;

  // Selecting rows and bulk update targets
  declare ledgerEntryCountTargets: HTMLSpanElement[];
  declare hasClearSelectionButtonTarget: boolean;
  declare clearSelectionButtonTarget: HTMLButtonElement;
  declare hasBulkUpdateButtonTarget: boolean;
  declare bulkUpdateButtonTarget: HTMLButtonElement;
  declare hasBulkUpdateModalTarget: boolean;
  declare bulkUpdateModalTarget: HTMLDialogElement;
  declare hasBulkEditErrorsTarget: boolean;
  declare bulkEditErrorsTarget: HTMLDivElement;
  declare ledgerEntryIdInputContainerTarget: HTMLDivElement;
  declare ledgerEntryIdInputTemplateTarget: HTMLTemplateElement;

  // Local Variables
  declare gridOptions: GridOptions<IDetailedLedgerEntry>;
  declare gridApi: GridApi;
  declare csvExporter: CsvExporter;

  declare saveGridStateHandler: (event: Event) => void;

  // This flag is used to prevent updates to date filters from causing a feedback
  // loop that clears the filter when a quick filter date is selected
  declare preventResetFromClearingGridFilters: boolean;

  connect() {
    const defaultNumberColDef = {
      minWidth: 120,
      aggFunc: "sum",
      enableValue: true,
      allowedAggFuncs: ["sum"],
      filter: "agNumberColumnFilter",
      filterParams: {
        buttons: ["reset", "apply"],
        filterOptions: [
          "equals",
          "notEqual",
          "lessThan",
          "lessThanOrEqual",
          "greaterThan",
          "greaterThanOrEqual",
          "inRange",
        ],
        maxNumConditions: 1,
      },
      cellClass: ["ag-right-aligned-cell", "font-mono"],
    };

    const setFilterParams = {
      values: (params: SetFilterValuesFuncParams) => {
        getFilterOptions(this.filtersUrlValue, params);
      },
      keyCreator: primaryKeyCreator,
      valueFormatter: objectValueFormatter,
      refreshValuesOnOpen: true,
      buttons: ["reset", "apply"],
      closeOnApply: true,
      defaultToNothingSelected: true,
      suppressSorting: true,
    };

    const setFilterColDefWithTreeParams = {
      ...setFilterParams,
      treeList: true,
      treeListPathGetter: (value) => {
        return value?.treePath;
      },
    };

    this.gridOptions = {
      columnDefs: [
        {
          field: "ledger",
          headerName: "Entity",
          enableRowGroup: true,
          valueGetter: (params) => params.data?.ledger,
          valueFormatter: (params) => params.value?.name,
          filter: "agSetColumnFilter",
          filterParams: {
            ...setFilterParams,
          },
        },
        {
          field: "account",
          headerName: "Account",
          enableRowGroup: true,
          valueGetter: (params) => params.data?.account,
          valueFormatter: (params) => params.value?.name,
          filter: "agSetColumnFilter",
          filterParams: {
            ...setFilterColDefWithTreeParams,
          },
        },
        {
          field: "effective_at",
          headerName: "Effective At",
          minWidth: 140,
          cellClass: "font-mono",
          cellDataType: "dateString",
          filter: "agDateColumnFilter",
          filterParams: {
            browserDatePicker: true,
            buttons: ["reset", "apply"],
            closeOnApply: true,
            inRangeInclusive: true,
            filterOptions: [
              "inRange",
              "lessThan",
              {
                displayKey: "lessThanOrEqual",
                displayName: "On or Before",
                predicate: ([filterValue], cellValue) => cellValue <= filterValue,
              },
              "greaterThan",
              "equals",
            ],
            maxValidYear: "2099",
            minValidYear: "1900",
            maxNumConditions: 1,
          },
        },
        {
          field: "debits",
          headerName: "Debits",
          ...defaultNumberColDef,
        },
        {
          field: "credits",
          headerName: "Credits",
          ...defaultNumberColDef,
        },
        {
          field: "accounting_amount",
          headerName: "Accounting Amount",
          hide: true,
          ...defaultNumberColDef,
        },
        {
          field: "description",
          minWidth: 200,
          cellRenderer: (params) => {
            if (params.node.group) {
              return params.value;
            }

            if (params.value) {
              return `<span title="${params.value}">${params.value}</span>`;
            } else {
              return `<span class="text-gray-400 italic">No description</span>`;
            }
          },
          filter: "agTextColumnFilter",
          filterParams: {
            filterOptions: ["contains", "notContains", "equals", "notEqual", "blank", "notBlank"],
            buttons: ["reset", "apply"],
            maxNumConditions: 1,
          },
        },
        {
          field: "account_code_group",
          headerName: "Account Code Group",
          enableRowGroup: true,
          hide: true,
          cellRenderer: accountCodeRenderer,
          cellRendererParams: {
            template: this.accountCodeTemplateTarget,
          },
          valueGetter: (params) => params.data?.account_code_group,
          filter: "agSetColumnFilter",
          filterParams: {
            ...setFilterParams,
          },
        },
        {
          field: "account_code",
          headerName: "Account Code",
          enableRowGroup: true,
          cellRenderer: accountCodeRenderer,
          cellRendererParams: {
            template: this.accountCodeTemplateTarget,
          },
          valueGetter: (params) => params.data?.account_code,
          filter: "agSetColumnFilter",
          filterParams: {
            ...setFilterColDefWithTreeParams,
          },
        },
        {
          field: "tag_groups",
          headerName: "Tag Groups",
          enableRowGroup: true,
          cellRenderer: tagRenderer,
          cellRendererParams: {
            template: this.tagTemplateTarget,
          },
          hide: true,
          sortable: false,
          filter: "agSetColumnFilter",
          filterParams: {
            ...setFilterParams,
          },
        },
        {
          field: "tags",
          headerName: "Tags",
          enableRowGroup: true,
          cellRenderer: tagRenderer,
          cellRendererParams: {
            template: this.tagTemplateTarget,
          },
          sortable: false,
          filter: "agSetColumnFilter",
          filterParams: {
            ...setFilterColDefWithTreeParams,
          },
        },
      ],
      defaultColDef: {
        sortable: true,
        flex: 1,
      },
      autoGroupColumnDef: {
        minWidth: 200,
        sortable: false,
        cellRendererParams: {
          totalValueGetter: (params) => {
            return `Sub Total ${params.value.name}`;
          },
        },
      },
      allowContextMenuWithControlKey: true,
      getContextMenuItems: (params: GetContextMenuItemsParams) => {
        return params.defaultItems.filter((item) => item !== "export");
      },
      rowModelType: "serverSide",
      getRowId: (params: GetRowIdParams) => {
        // There's a lot going on here, essentially we're creating a unique identifier for the row.
        // When we're grouping by rows, we can pull the parent keys from the row node. We're concatenating the
        // parent keys and the data ID to create a unique identifier for the row. The id would be something like
        // "ledger_123-acct_tmpl_456-le_789" if we were grouping by ledgers, and then accounts.
        // When we're not grouping by rows, we rely on the data ID to create a unique identifier for the row.
        const objectIds: Set<string> = new Set();

        // The parent keys can be an array of strings or objects (we're using objects most of the time via the value
        // getter on the column definition)
        (params.parentKeys || []).flat().forEach((key: string | { id: string }) => {
          if (typeof key === "string") {
            objectIds.add(key);
          } else if (typeof key === "object") {
            objectIds.add(key.id);
          }
        });

        if (params.data.id !== undefined && params.data.id !== null) {
          objectIds.add(params.data.id.toString());
        }

        const rowGroupCols = params.api.getRowGroupColumns();
        const thisGroupCol = rowGroupCols[params.level];

        // If we're grouping by rows, here we pull the keys from the parent nodes
        if (thisGroupCol) {
          let additionalData = params.data[thisGroupCol.getColDef().field];

          // Additional data can be an array of objects, so we need to check for that
          if (Array.isArray(additionalData)) {
            additionalData = { id: additionalData.map((item) => item.id).join("-") };
          }

          if (additionalData) {
            const identifier = additionalData.id || additionalData?.name?.toLowerCase();

            objectIds.add(identifier);
          }
        }

        const uniqueKey = Array.from(objectIds).join("-");

        return uniqueKey;
      },
      onCellDoubleClicked: (event: CellDoubleClickedEvent) => {
        if (this.hasShowLedgerTransactionButtonWrapperTarget) {
          const theButton = this.showLedgerTransactionButtonWrapperTarget.querySelector("button");
          const cardData = JSON.parse(theButton?.getAttribute("data-card") || "{}");
          const ledgerTransactionId = event.data?.ledger_transaction?.id;

          if (ledgerTransactionId) {
            cardData.turboSrc = `/ledger_management/ledger_transactions/${ledgerTransactionId}/modal_show`;
            theButton?.setAttribute("data-card", JSON.stringify(cardData));

            theButton.click();
          } else {
            console.warn("No ledger transaction ID found for this row, could not show modal");
          }
        } else {
          console.warn("No show ledger transaction button wrapper target found, could not show modal");
        }
      },
      serverSideDatasource: createServerSideDatasource(
        this.datasourceUrlValue,
        this.setLoadingState.bind(this),
        this.getCashFlowFilter.bind(this),
      ),
      isServerSideGroupOpenByDefault: (_params: IsServerSideGroupOpenByDefaultParams) => {
        return this.expandAllGroupsValue;
      },
      dataTypeDefinitions: {
        ...dataTypeDefinitions,
      },
      groupTotalRow: "bottom",
      rowGroupPanelShow: "always",
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
            },
          },
          {
            id: "filters",
            labelDefault: "Filters",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
            },
          },
        ] as ToolPanelDef[],
      },
      pivotMode: false,
      pivotPanelShow: "never",
      suppressServerSideFullWidthLoadingRow: true,
      // Row selection logic
      rowSelection: {
        mode: "multiRow",
        checkboxes: false,
        headerCheckbox: false,
        enableClickSelection: "enableSelection",
        isRowSelectable: (node: IRowNode) => {
          if (!this.isRowSelectionEnabledValue || this.isGroupingByRowsValue) {
            return false;
          }

          // Groups nodes are not selectable
          return !node.group;
        },
      },
      onSelectionChanged: (event) => {
        if (event.source === "gridInitializing") {
          return;
        }

        const nodeIds = (event.api.getServerSideSelectionState().toggledNodes || []) as string[];

        this.setSelectedIdsFromNodeIds(nodeIds);

        this.saveGridState(new Event("selectionChanged"));
      },
      onColumnRowGroupChanged: (event) => {
        const groupingByRows = event.api.getRowGroupColumns().length !== 0;

        // Note we are deselecting all nodes here before setting the isGroupingByRowsValue value. We use the
        // isGroupingByRowsValue value to determine if the rows are selectable or not, and if we don't deselect
        // all nodes before setting the value, then the selection state cannot be changed.
        if (groupingByRows !== this.isGroupingByRowsValue) {
          event.api.deselectAll();

          this.isGroupingByRowsValue = groupingByRows;
        }

        this.saveGridState(new Event("columnRowGroupChanged"));
      },
      onFilterChanged: (event) => {
        this.handleFilterChanged(event);
        this.saveGridState(new Event("filterChanged"));
      },
      onSortChanged: (event) => {
        if (event.source === "gridInitializing") {
          return;
        }

        this.saveGridState(new Event("sortChanged"));
      },
      onColumnVisible: (event) => {
        if (event.source === "gridInitializing") {
          return;
        }

        this.saveGridState(new Event("columnVisible"));
      },
      onGridPreDestroyed: (_event) => {
        // The Grid is rarely destroyed in real use cases, but it is regularly destroyed and recreated when developing,
        // so we save it off here to make sure we don't lose the state.
        this.saveGridState(new Event("gridPreDestroyed"));
      },
      onGridReady: (_event) => {
        const savedState = JSON.parse(sessionStorage.getItem("registerState") || "{}");
        const nodeIds = (savedState?.rowSelection?.toggledNodes || []) as string[];

        this.setSelectedIdsFromNodeIds(nodeIds);

        if (this.selectedIdsValue.length === 0) {
          this.clearSelectedIds();
        }

        let filterModel
        if (this.filterModelValue && Object.keys(this.filterModelValue).length > 0) {
          filterModel = this.filterModelValue;
        } else {
          filterModel = savedState?.filter?.filterModel;
        }

        if (filterModel) {
          this.gridApi.setFilterModel(filterModel);

          // Remove search params from the URL after applying the filter model
          history.replaceState(null, "", document.location.pathname);
        }

        if (this.columnStateValue) {
          this.gridApi.applyColumnState({
            state: this.columnStateValue,
            applyOrder: false,
            defaultState: null,
          });
        }
      },
      initialState: {
        ...JSON.parse(sessionStorage.getItem("registerState") || "{}"),
      },
    };

    this.gridApi = createGrid(this.gridTarget, this.gridOptions);
    this.csvExporter = new CsvExporter(this.gridApi);
  }

  disconnect() {
    this.gridApi.destroy();
  }

  saveGridState(_event: Event) {
    const currentState = this.gridApi.getState();

    // The full grid state can be quite large, so we save it off in session storage. Separately, we also save off the
    // filter model and column state as stimulus values for debugging purposes.
    sessionStorage.setItem("registerState", JSON.stringify(currentState));

    this.filterModelValue = this.gridApi.getFilterModel();
    this.columnStateValue = this.gridApi.getColumnState().map((columnState) => {
      return {
        colId: columnState.colId,
        hide: columnState.hide,
        sort: columnState.sort,
        sortIndex: columnState.sortIndex,
        rowGroup: columnState.rowGroup,
        rowGroupIndex: columnState.rowGroupIndex,
      };
    });
  }

  async applyDateRange(event: Event & { params: { startDate: string | null; endDate: string | null } }) {
    if (this.preventResetFromClearingGridFilters) {
      // Skip the logic if we are resetting the quick date filter but want to keep the current date filters
      // This happens when the user selects a quick filter and then manually changes the filter dates in the grid
      return;
    }

    const select = event.target as HTMLSelectElement;
    const selectedOption = select.options[select.selectedIndex];

    if (!selectedOption) {
      return;
    }

    const startDate = selectedOption.dataset.startDate;
    const endDate = selectedOption.dataset.endDate;

    if (!startDate && !endDate) {
      await this.gridApi.setColumnFilterModel("effective_at", null);
      this.gridApi.onFilterChanged();

      return;
    }

    let filterModel = {};

    if (startDate && !endDate) {
      filterModel = {
        type: "greaterThan",
        dateFrom: startDate,
      };
    } else if (!startDate && endDate) {
      filterModel = {
        type: "lessThan",
        dateTo: endDate,
      };
    } else {
      filterModel = {
        type: "inRange",
        dateFrom: startDate,
        dateTo: endDate,
      };
    }

    await this.gridApi.setColumnFilterModel("effective_at", filterModel);

    this.gridApi.onFilterChanged("quickFilter");
  }

  resetFilters() {
    this.gridApi.setFilterModel(null);
  }

  resetQuickDateFilter() {
    this.dispatch("reset-quick-date-filter");
  }

  handleFilterChanged(event) {
    // We are using the 'quickFilter' source to identify that the filter change is coming from a user-initiated quick filter selection
    // This is a workaround to prevent the grid from clearing the date filter when the user selects a quick date filter
    // The quickFilter source is an AG Grid feature, though, so we are co-opting the meaning for this use-case and may have to re-evaluate
    // if we wish to take advantage of the AG Grid quick filter feature in the future
    if (event.source === "quickFilter") {
      return;
    }

    const newFilterModel = this.gridApi.getFilterModel();
    const oldFilterModel = this.filterModelValue;
    const dateColumns = ["effective_at"];

    // Check if the date filter was removed
    const dateFilterRemoved = dateColumns.some((colId) => {
      const oldFilter = oldFilterModel[colId];
      const newFilter = newFilterModel[colId];
      return oldFilter && !newFilter;
    });

    // Check if the date filter has a different dateFrom or dateTo
    const dateFilterChanged = dateColumns.some((colId) => {
      const oldFilter = oldFilterModel[colId];
      const newFilter = newFilterModel[colId];
      return (
        oldFilter && newFilter && (oldFilter.dateFrom !== newFilter.dateFrom || oldFilter.dateTo !== newFilter.dateTo)
      );
    });

    if (dateFilterRemoved) {
      this.resetQuickDateFilter();
    } else if (dateFilterChanged) {
      this.preventResetFromClearingGridFilters = true;
      this.resetQuickDateFilter();
      this.preventResetFromClearingGridFilters = false;
    }
  }

  // This is our custom CSV export that exports all the data from the server
  exportCsvLineItems(event: Event) {
    this.csvExporter.exportCSV(event, "advanced_register_export");
  }

  // This is the native AG Grid Export that exports what is currently visible in the grid
  exportCsvView() {
    this.gridApi.exportDataAsCsv({
      /*
       * The processRowGroupCallback is invoked once per row group and returns a string to be
       * displayed in the group cell. Here we are handling adding indentations to the group cell
       * based on how nested the row group is. This callback is adapted from the default behavior
       * in the createValueForGroupNode in ag-grid-enterprise.js.
       */
      processRowGroupCallback: (params: ProcessRowGroupForExportParams) => {
        let node = params.node;
        const column = params.column;
        const key = node.groupData?.[column.getId()];
        const isFooter = node.footer;
        const breadcrumbIndentations = [];

        while (node.parent) {
          node = node.parent;
          breadcrumbIndentations.push("  ");
        }

        const groupValue = breadcrumbIndentations.concat(key).join("  ");

        if (isFooter) {
          return `${groupValue}    Sub Total`;
        } else {
          return groupValue;
        }
      },
      processCellCallback: (params: ProcessCellForExportParams) => {
        if (params.column.getColDef().field === "tags" && Array.isArray(params.value)) {
          return params.value.map((tag) => tag.name).join("; ");
        } else {
          return params.value;
        }
      },
    });
  }

  isGroupingByRowsValueChanged(_oldValue: boolean, newValue: boolean) {
    if (newValue) {
      this.clearSelectedIds();
    }
  }

  selectedIdsValueChanged() {
    this.updateBulkUpdateButton();
    this.updateClearSelectionButton();
    this.updateBulkEditErrors();
  }

  filterForCashFlowValueChanged() {
    if (this.filterForCashFlowValue) {
      this.cashFlowAlertTarget.classList.remove("hidden");
    } else {
      this.cashFlowAlertTarget.classList.add("hidden");
    }
  }

  removeCashFlowFilter() {
    this.filterForCashFlowValue = false;

    this.gridApi.onFilterChanged();
  }

  updateBulkUpdateButton() {
    this.ledgerEntryCountTargets.forEach((ledgerEntryCountTarget) => {
      if (this.selectedIdsValue.length > 0) {
        ledgerEntryCountTarget.classList.remove("hidden");
        ledgerEntryCountTarget.textContent = this.selectedIdsValue.length.toString();
      } else {
        ledgerEntryCountTarget.classList.add("hidden");
        ledgerEntryCountTarget.textContent = "";
      }
    });

    if (this.hasBulkUpdateButtonTarget) {
      this.bulkUpdateButtonTarget.disabled =
        this.selectedIdsValue.length === 0 || this.selectedIdsValue.length > this.maxSelectableIdsValue;
    }
  }

  updateClearSelectionButton() {
    if (this.hasClearSelectionButtonTarget) {
      if (this.selectedIdsValue.length > 0) {
        this.clearSelectionButtonTarget.classList.remove("hidden");
      } else {
        this.clearSelectionButtonTarget.classList.add("hidden");
      }
    }
  }

  updateBulkEditErrors() {
    if (this.selectedIdsValue.length > this.maxSelectableIdsValue) {
      this.bulkEditErrorsTarget.classList.remove("hidden");
      this.bulkEditErrorsTarget.querySelector("#bulk-edit-delta")!.textContent = (
        this.selectedIdsValue.length - this.maxSelectableIdsValue
      ).toString();
    } else {
      this.bulkEditErrorsTarget.classList.add("hidden");
    }
  }

  showBulkUpdateModal() {
    this.bulkUpdateModalTarget.showModal();
  }

  hideBulkUpdateModal() {
    this.bulkUpdateModalTarget.close();
  }

  bulkUpdateSubmit(event: Event) {
    event.preventDefault();

    const submitButton = event.currentTarget as HTMLButtonElement;
    submitButton.disabled = true;

    const form = submitButton.form as HTMLFormElement;

    // Clear the ledger entry id input container
    this.ledgerEntryIdInputContainerTarget.innerHTML = "";

    // Add the selected ledger entry ids to the form before submitting
    this.selectedIdsValue.forEach((id) => {
      const ledgerEntryIdInput = document.importNode(this.ledgerEntryIdInputTemplateTarget.content, true);
      const ledgerEntryIdInputEl = ledgerEntryIdInput.querySelector("input");
      ledgerEntryIdInputEl.value = id.toString();

      this.ledgerEntryIdInputContainerTarget.appendChild(ledgerEntryIdInput);
    });

    form.requestSubmit();
  }

  clearSelectedIds() {
    this.gridApi.deselectAll();
  }

  setSelectedIdsFromNodeIds(nodeIds: string[]) {
    this.selectedIdsValue =
      nodeIds
        .map((nodeId: string) => {
          // When restoring the state, AG Grid uses the node ID instead of the ID we need to actually update
          // the ledger entries. So we only return the node ID if it's a ledger entry ID.
          if (nodeId?.startsWith("le_")) {
            return nodeId;
          } else {
            return null;
          }
        })
        .filter((id: string | null) => id !== null) || [];
  }

  setLoadingState(isLoading: boolean) {
    this.gridIsLoadingValue = isLoading;
  }

  getCashFlowFilter() {
    return this.filterForCashFlowValue;
  }
}
